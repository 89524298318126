import axios from 'axios';
import GraniteError from 'lib/granite-error';
import StorageGateway from 'lib/storage-gateway';
import { toast } from 'react-toastify';
import Singleton from 'lib/singleton';
const headers = {'Content-Type': 'application/json'};

// const api = axios.create({baseURL, headers, timeout: 200000});
const axiosInstance = axios.create();


axiosInstance.interceptors.request.use(
  (configuration) => {
    const axiosConfig = configuration;
    const singleton = new Singleton();
    const oAuthToken = singleton.oAuthToken;
    const companyId = singleton.company_id;
    const uniqueUuid = singleton.uniqueUuid;
    if (axiosConfig.url.indexOf("api.here") === -1){
      headers["Access-Control-Allow-Origin"] = "*";
      axiosConfig.headers["Cache-Control"] = "no-cache";
      axiosConfig.headers["X-XSS-Protection"] = "1; mode=block";
      axiosConfig.headers["Strict-Transport-Security"] = "max-age=31536000";
      axiosConfig.headers["X-Content-Type-Options"] = "nosniff";
      axiosConfig.headers.language = singleton.language ? singleton.language : 'english';
      if (companyId) axiosConfig.headers.company = companyId;
      // if (authToken) axiosConfig.headers.Authorization =`Token ${authToken}`;
      if (uniqueUuid) axiosConfig.headers.uniqueUuid = uniqueUuid;
    }
   
    // if (pluginToken && axiosConfig.url.indexOf("192") !== -1)
    //   {
    //     axiosConfig.headers.Authorization = `${pluginToken}`;
    //   }
    if (axiosConfig.url.indexOf("api.here") !== -1){
      axiosConfig.headers.Authorization =`OAuth oauth_consumer_key="aiyd57hR7RDsz9tLXQLtRw",oauth_signature_method="HMAC-SHA1",oauth_timestamp="1588333994",oauth_nonce="tXmnsXev4EI",oauth_version="1.0",oauth_signature="0N0BqH6ZZ9K7P7NchmGqkJkBEi0%3D"`;
      axiosConfig.headers["Content-Type"] = "application/x-www-form-urlencoded";
      axiosConfig.headers["Accept"] = '*/*'

    }

    if (oAuthToken) axiosConfig.headers.Authorization = `Token ${oAuthToken}`;
    return axiosConfig;

    // const singleton = new AuthSingleton();
    // if (singleton.authToken) {
    //   axiosConfig.headers.authorization = `${singleton.authToken}`;
    //   // axiosConfig.headers.is_web = true;
    // }


    //axiosConfig.headers['Access-Control-Allow-Origin'] = '*';
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => Promise.resolve(response),
  (err) => {

    if (err.response &&
      (err.response.status === 401 ||
        err.response.data.detail === 'Invalid token.')
    ) {

      // cookie.remove('authToken', { path: '/' });
      StorageGateway.remove('oAuthToken', {path: '/'})
      // cookie.remove('Authorization', { path: '/', domain });
      StorageGateway.remove('company_id', { path: '/' });
      StorageGateway.remove('uniqueUuid', { path: '/' });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      // toast.error('Your session has expired.');
    }
    else if(err.response && err.response.status === 400) {
      if(err.response.data.auth_error) {
        toast.error(err.response.data.auth_error[0]);
        // cookie.remove('authToken', { path: '/' });
        StorageGateway.remove('oAuthToken', {path: '/'})
        // cookie.remove('Authorization', { path: '/', domain });
        StorageGateway.remove('company_id', { path: '/' });
        StorageGateway.remove('uniqueUuid', { path: '/' });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else if(err.response.data.method_error) {
        toast.error(err.response.data.method_error[0]);
      }
    }
    const data = err.response.data;
    throw new GraniteError(data, true);
  }
);

export default axiosInstance;
