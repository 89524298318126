import GraniteError from "lib/granite-error";
import EventEmitter from "lib/event-emitter";
import Singleton from "lib/singleton";
import TRMSGS from "constants/Translations/Localization";

const EVENT_TYPES = {
  GET_MANDATORY_FEEDBACK_QUESTIONS: "GET_MANDATORY_FEEDBACK_QUESTIONS",
  POST_DATA_SUCCESS: "POST_DATA_SUCCESS",
  UPDATE_SESSION_SUCCESS: "UPDATE_SESSION_SUCCESS",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_BUTTON_LOADER: "SHOW_BUTTON_LOADER",
  HIDE_BUTTON_LOADER: "HIDE_BUTTON_LOADER",
};

class mandatoryFeedbackUsecase {
  mandatoryFeedbackAPIGateway = null;

  constructor(mandatoryFeedbackAPIGateway) {
    this.mandatoryFeedbackAPIGateway = mandatoryFeedbackAPIGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
    this.singleton = new Singleton();
  }

  toString() {
    return "mandatoryFeedbackUsecase";
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  async postFeedbackResponse(data) {
   
      let errors = {};
      if (
        data.response &&
        data.response.questions &&
        data.response.questions.length
      ) {
        data.response.questions.forEach((i) => {
          const sequence = i.sequence_of_configuration;
          if (i.answer && (!i.answer.length || !i.answer[0].value ) && i.behaviour === "MANDATORY") {
            errors[`${sequence}`] = `${
              i.definition === "SIGNATURE"
                ? TRMSGS.pleaseSign
                : i.definition === "FILE_UPLOAD"
                ? TRMSGS.pleaseUploadFile
                : TRMSGS.pleaseAnswerThisQuestion
            }`;
          }
          if (
            i.answer &&
            i.answer.length &&
            i.behaviour === "MANDATORY" &&
            i.definition === "DATE_TIME_SELECT" &&
            (!i.answer[0].date || !i.answer[0].time)
          ) {
            errors[`${sequence}`] = `${TRMSGS.pleaseAnswerThisQuestion}`;
          }
        });
      }
      console.log(errors,'dfjksdhk')
      if (Object.getOwnPropertyNames(errors).length > 0) {
        throw new GraniteError(errors);
      }
      this.eventEmitter.emit({
        type: EVENT_TYPES.SHOW_BUTTON_LOADER,
      });
      const response = await this.mandatoryFeedbackAPIGateway.postFeedback(
        data
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.POST_DATA_SUCCESS,
        data: response.data,
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_BUTTON_LOADER,
      });
    
  }

  async updateSessionIdToForm(data, responseId) {
    const response = await this.mandatoryFeedbackAPIGateway.updateSessionId({
      ...data,
      responseId: responseId,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_SESSION_SUCCESS,
      data: response,
    });
  }

  async getMandatoryFeedbackQuestions(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    const response =
      await this.mandatoryFeedbackAPIGateway.getMandatoryQuestions(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_MANDATORY_FEEDBACK_QUESTIONS,
      data: response,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
    if (response.questions === 0) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.POST_DATA_SUCCESS,
        data: response,
      });
    }
  }

  async getFormDetails(id) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    const response = await this.mandatoryFeedbackAPIGateway.getFormDetails(id);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_MANDATORY_FEEDBACK_QUESTIONS,
      data: response,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
    if (response.questions === 0) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.POST_DATA_SUCCESS,
        data: response,
      });
    }
  }
}

export default mandatoryFeedbackUsecase;
